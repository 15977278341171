exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pdpa-js": () => import("./../../../src/pages/pdpa.js" /* webpackChunkName: "component---src-pages-pdpa-js" */),
  "component---src-pages-team-azam-js": () => import("./../../../src/pages/team/azam.js" /* webpackChunkName: "component---src-pages-team-azam-js" */),
  "component---src-pages-team-ku-jaafar-js": () => import("./../../../src/pages/team/ku-jaafar.js" /* webpackChunkName: "component---src-pages-team-ku-jaafar-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-post-js-content-file-path-contents-1-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/1.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-1-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-10-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/10.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-10-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-11-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/11.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-11-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-12-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/12.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-12-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-13-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/13.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-13-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-14-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/14.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-14-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-15-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/15.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-15-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-16-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/16.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-16-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-17-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/17.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-17-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-18-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/18.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-18-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-19-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/19.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-19-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-2-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/2.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-2-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-20-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/20.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-20-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-21-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/21.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-21-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-22-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/22.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-22-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-3-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/3.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-3-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-4-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/4.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-4-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-5-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/5.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-5-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-6-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/6.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-6-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-7-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/7.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-7-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-8-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/8.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-8-mdx" */),
  "component---src-templates-post-js-content-file-path-contents-9-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/9.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-9-mdx" */),
  "component---src-templates-post-list-js-content-file-path-contents-1-mdx": () => import("./../../../src/templates/postList.js?__contentFilePath=/vercel/path0/contents/1.mdx" /* webpackChunkName: "component---src-templates-post-list-js-content-file-path-contents-1-mdx" */),
  "component---src-templates-post-list-js-content-file-path-contents-2-mdx": () => import("./../../../src/templates/postList.js?__contentFilePath=/vercel/path0/contents/2.mdx" /* webpackChunkName: "component---src-templates-post-list-js-content-file-path-contents-2-mdx" */),
  "component---src-templates-post-list-js-content-file-path-contents-3-mdx": () => import("./../../../src/templates/postList.js?__contentFilePath=/vercel/path0/contents/3.mdx" /* webpackChunkName: "component---src-templates-post-list-js-content-file-path-contents-3-mdx" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

